import { useCallback, useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin'
import { useGetCustomDomainsQuery, useRefreshCustomDomainsMutation } from '@sevenrooms/admin-state/useCustomDomainApi'
import type { CustomDomain } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Loader, Spreadsheet, type DataTableColumn } from '@sevenrooms/core/ui-kit/layout'
import { adminCustomDomainsMessages } from '../../locales'

export interface AdminCustomDomainTableRow extends CustomDomain {
  id: string
}

export function AdminCustomDomains() {
  const { formatMessage } = useLocales()
  const { data, isLoading } = useGetCustomDomainsQuery()
  const [refreshCustomDomains, { isLoading: isRefreshing }] = useRefreshCustomDomainsMutation()
  const [refreshedCustomDomains, setCustomDomains] = useState<CustomDomain[]>([])

  const refreshTableData = useCallback(async () => {
    const response = await refreshCustomDomains().unwrap()
    setCustomDomains(response)
  }, [refreshCustomDomains, setCustomDomains])

  const tableData = refreshedCustomDomains.length > 0 ? refreshedCustomDomains : data
  const rows = tableData?.map(customDomain => ({ ...customDomain, id: customDomain.customDomainId })) || []
  const columns = useCallback<(rows: AdminCustomDomainTableRow[]) => DataTableColumn<typeof rows[number]>[]>(
    () => [
      {
        header: formatMessage(adminCustomDomainsMessages.tableVenueGroupNameHeader),
        render: 'venueGroupName',
        sortable: true,
        sortType: 'string',
        minWidth: 200,
      },
      {
        header: formatMessage(adminCustomDomainsMessages.tableCustomDomainHeader),
        render: 'customDomain',
        sortable: true,
        sortType: 'string',
        minWidth: 200,
      },
      {
        header: formatMessage(adminCustomDomainsMessages.tableVerifiedHeader),
        key: 'verified',
        render: (row: AdminCustomDomainTableRow) =>
          row.verified ? (
            <>
              <Icon name="VMSWeb-checkbox-marked-circle" color="success" size="lg" />{' '}
              {formatMessage(adminCustomDomainsMessages.verifiedStatus)}
            </>
          ) : (
            <>
              <Icon name="VMSWeb-close-circle" color="inactive" size="lg" /> {formatMessage(adminCustomDomainsMessages.notVerifiedStatus)}
            </>
          ),
        sortable: false,
        sortType: 'string',
        minWidth: 100,
        maxWidth: 100,
      },
    ],
    [formatMessage]
  )

  return (
    <>
      <AdminPageMeta title={formatMessage(adminCustomDomainsMessages.title)} />

      <AdminPageContent
        title={formatMessage(adminCustomDomainsMessages.title)}
        actions={
          <Button
            disabled={isRefreshing}
            variant="primary"
            icon="VMSWeb-refresh"
            onClick={() => refreshTableData()}
            data-test="refresh-button"
          >
            {formatMessage(adminCustomDomainsMessages.refreshList)}
          </Button>
        }
      >
        <Box pt="lm" pb="lm">
          {isLoading ? (
            <Loader />
          ) : (
            <Box mb="lm">
              <Spreadsheet data={rows} columns={columns(rows)} initialPageSize={40} />
            </Box>
          )}
        </Box>
      </AdminPageContent>
    </>
  )
}
