import { useMemo, useEffect, useState, useCallback } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import {
  useGetAdminVenuePhoneNumbersDataQuery,
  useSaveVenuePhoneNumbersMutation,
  useDeleteVenuePhoneNumberMutation,
  type AdminVenuePhoneNumber,
  type SMSPhonePurpose,
} from '@sevenrooms/core/api'
import { useForm, useFieldArray } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Form, Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Breadcrumbs, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminVenuePhoneNumbersMessages } from '../../locales'
import { PhoneNumberCard } from './PhoneNumberCard'
import { PhoneNumberModal } from './PhoneNumberModal'
import {
  useVenuePhoneNumbersForm,
  newPhoneNumberDefaults,
  type VenuePhoneNumbersForm,
  isAutocreated,
  tollFreeDefaults,
} from './VenuePhoneNumbers.zod'

interface PhoneNumbersFormProps {
  phoneNumbers: AdminVenuePhoneNumber[]
  venueGroupId: string
  venueId: string
  venueName: string
  venueGroupName: string
}

export function PhoneNumberForm({ phoneNumbers, venueGroupId, venueId, venueName, venueGroupName }: PhoneNumbersFormProps) {
  const [phoneNumberToDelete, setPhoneNumberToDelete] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false)

  const nav = useNavigation()
  const { isFetching } = useGetAdminVenuePhoneNumbersDataQuery({ venueId })
  const { formatMessage } = useLocales()
  const [saveVenuePhoneNumbers, { isLoading: isSaving, error: savingError }] = useSaveVenuePhoneNumbersMutation()
  const [deletePhoneNumber, { isLoading: isDeleting }] = useDeleteVenuePhoneNumberMutation()

  const initialPhoneNumbers = useMemo(
    () =>
      phoneNumbers.map(number => ({
        ...number,
        twilioAutocreate: false,
        // We currently only support one purpose per phone number, so take the first of the array.
        // In the future, we will support multiple purposes per phone number.
        purpose: number.purpose[0] as SMSPhonePurpose,
        statusInfo:
          number.tfVerificationStatus && number.tfVerificationStatusUpdatedAt
            ? {
                status: number.tfVerificationStatus,
                description:
                  number.tfErrorCode || number.tfRejectionReason ? `${number.tfErrorCode} ${number.tfRejectionReason}` : undefined,
                updatedAt: new Date(number.tfVerificationStatusUpdatedAt),
              }
            : null,
        tollFreeVerification: Object.fromEntries(
          Object.entries(tollFreeDefaults).map(
            ([k, v]) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              [k, number[k] || v]
            // error is: cannot use index of type string on CamelCaseKeys[...] ... but these are explicitly strings per what camelcase DOES.
          )
        ),
      })),
    [phoneNumbers]
  )

  const schema = useVenuePhoneNumbersForm()
  const form = useForm(schema, { defaultValues: { phoneNumbers: initialPhoneNumbers } })
  const {
    field,
    formState: { isDirty },
    reset,
    getValues,
    setValue,
  } = form

  const trySaveVenuePhoneNumbers = async (formData: VenuePhoneNumbersForm) => {
    // We currently only support one purpose per phone number.
    // Therefore, create a "purpose" array with the single value from the form.
    // In the future, we will support multiple purposes per phone number.
    const phoneNumbers = formData.phoneNumbers.map(
      ({ statusInfo: _statusInfo, tollFreeVerification, isWhatsappRegistrationComplete: _isWhatsappRegistrationComplete, ...number }) => ({
        ...number,
        ...tollFreeVerification,
        purpose: [number.purpose],
        twilioAccountSid: isAutocreated(number) ? null : number.twilioAccountSid,
        twilioAuthToken: isAutocreated(number) ? null : number.twilioAuthToken,
      })
    )
    try {
      await saveVenuePhoneNumbers({
        venueId,
        phoneNumbers,
      }).unwrap()
      notify({
        content: formatMessage(adminVenuePhoneNumbersMessages.saveSuccess),
        type: 'success',
      })
    } catch {
      notify({
        content: formatMessage(adminVenuePhoneNumbersMessages.saveError),
        type: 'error',
      })
    }
  }

  // Reset the form state if phone numbers are updated.
  // This happens when new data is fetched (ie, after the form is submitted).
  useEffect(() => {
    reset({ phoneNumbers: initialPhoneNumbers })
  }, [initialPhoneNumbers, reset])

  const { fields, prepend, remove } = useFieldArray(field, 'phoneNumbers')

  const removeOrDisplayDeleteModal = (idx: number) => {
    const venuePhoneNumberId = getValues(`phoneNumbers.${idx}`).id
    if (venuePhoneNumberId) {
      setPhoneNumberToDelete(venuePhoneNumberId)
      setShowDeleteModal(true)
    } else {
      remove(idx)
    }
  }

  const dismissDeleteModal = () => {
    setPhoneNumberToDelete('')
    setShowDeleteModal(false)
  }

  const tryDeletePhoneNumber = useCallback(async () => {
    try {
      await deletePhoneNumber({ venuePhoneNumberId: phoneNumberToDelete }).unwrap()
      notify({ content: formatMessage(adminVenuePhoneNumbersMessages.deleteSuccess), type: 'success' })
    } catch {
      notify({ content: formatMessage(adminVenuePhoneNumbersMessages.deleteError), type: 'error' })
    }
    dismissDeleteModal()
  }, [phoneNumberToDelete, deletePhoneNumber, formatMessage])

  return (
    <Form {...form} onSubmit={data => trySaveVenuePhoneNumbers(data)} onInvalid={() => {}}>
      <AdminPageMeta title={formatMessage(adminVenuePhoneNumbersMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            {!isDirty && (
              <Button
                data-test="venue-configuration-breadcrumb-button"
                href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
                variant="tertiary"
                target="_self"
                isExternal
                noPadding
              >
                {formatMessage(adminVenuePhoneNumbersMessages.venueConfigurationBreadcrumb)}
              </Button>
            )}
            {isDirty && (
              <Button
                data-test="venue-configuration-breadcrumb-dirty-button"
                onClick={() => setShowDiscardChangesModal(true)}
                variant="tertiary"
                noPadding
              >
                {formatMessage(adminVenuePhoneNumbersMessages.venueConfigurationBreadcrumb)}
              </Button>
            )}
            <Text>{formatMessage(adminVenuePhoneNumbersMessages.title)}</Text>
          </Breadcrumbs>
        }
        title={venueName}
        actions={
          <HStack spacing="s">
            <Button
              data-test="add-phone-number-button"
              icon="VMSWeb-add"
              variant="tertiary"
              onClick={() => prepend(newPhoneNumberDefaults)}
            >
              {formatMessage(adminVenuePhoneNumbersMessages.addPhoneNumber)}
            </Button>
            <Button data-test="save-button" variant="primary" type="submit" disabled={!isDirty || isSaving || isDeleting || isFetching}>
              {formatMessage(commonMessages.saveChanges)}
            </Button>
          </HStack>
        }
      >
        <VStack pt="m" spacing="lm">
          {savingError && (
            <Box m="l">
              <Text color="error">{savingError as string}</Text>
            </Box>
          )}
          {fields.length === 0 && <Box m="l">{formatMessage(adminVenuePhoneNumbersMessages.noPhoneNumbers)}</Box>}
          {fields.map((phoneNumber, index) => (
            <PhoneNumberCard
              venueGroupId={venueGroupId}
              venueId={venueId}
              venueName={venueName}
              venueGroupName={venueGroupName}
              field={field.prop(`phoneNumbers.${index}`)}
              key={phoneNumber.id}
              remove={() => removeOrDisplayDeleteModal(index)}
              clearAutocreate={() => setValue(`phoneNumbers.${index}.twilioAutocreate`, false)}
            />
          ))}
        </VStack>
        <Window active={showDeleteModal}>
          <PhoneNumberModal
            titleText={formatMessage(adminVenuePhoneNumbersMessages.deleteModalTitle)}
            bodyText={formatMessage(adminVenuePhoneNumbersMessages.deleteModalBody)}
            onClose={dismissDeleteModal}
            actions={
              <HStack spacing="s">
                <Button data-test="cancel-button" variant="tertiary" onClick={dismissDeleteModal} disabled={isDeleting}>
                  {formatMessage(commonMessages.cancel)}
                </Button>
                <Button data-test="delete-button" variant="primary-warning" onClick={tryDeletePhoneNumber} disabled={isDeleting}>
                  {formatMessage(commonMessages.delete)}
                </Button>
              </HStack>
            }
          />
        </Window>
        <Window active={showDiscardChangesModal}>
          <PhoneNumberModal
            titleText={formatMessage(adminVenuePhoneNumbersMessages.discardChangesModalTitle)}
            bodyText={formatMessage(adminVenuePhoneNumbersMessages.discardChangesModalBody)}
            onClose={() => {
              setShowDiscardChangesModal(false)
            }}
            actions={
              <HStack spacing="s">
                <Button
                  data-test="back-to-editing-button"
                  variant="tertiary"
                  onClick={() => {
                    setShowDiscardChangesModal(false)
                  }}
                >
                  {formatMessage(commonMessages.backToEditing)}
                </Button>
                <Button
                  data-test="discard-changes-button"
                  href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
                  variant="primary-warning"
                  target="_self"
                  isExternal
                >
                  {formatMessage(commonMessages.discardChanges)}
                </Button>
              </HStack>
            }
          />
        </Window>
      </AdminPageContent>
    </Form>
  )
}
