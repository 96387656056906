import { useEffect } from 'react'
import { useGetAdminVenuePhoneNumbersDataQuery, SMSProvider, TwilioAutocreateStatus, TwilioPhoneType } from '@sevenrooms/core/api'
import type { CountryCode } from '@sevenrooms/core/domain'
import { type Field, useWatch, useWatchMany } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { FormPhoneNumberInput, Button, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, BaseSection, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text, StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { adminVenuePhoneNumbersMessages } from '../../locales'
import { FluidCheckbox, FluidControl, FluidInput, FluidSelect, FluidSelectNullable, InputGroup } from './FormControls'
import { TollFreeVerificationCard } from './TollFreeVerificationCard'
import type { PhoneNumberFormSchema } from './VenuePhoneNumbers.zod'

interface PhoneNumberCardProps {
  field: Field<PhoneNumberFormSchema>
  venueId: string
  venueGroupId: string
  venueName: string
  venueGroupName: string
  remove: () => void
  clearAutocreate: () => void
}
function AutoreatedLabel({ text }: { text: string }) {
  return <StatusLabel variant="success">{text}</StatusLabel>
}

function ManuallyCreatedLabel({ text }: { text: string }) {
  return <StatusLabel variant="inactive">{text}</StatusLabel>
}

const US_CA_COUNTRY_CODES: CountryCode[] = ['us', 'ca']

export function PhoneNumberCard({
  field,
  venueId,
  venueGroupId,
  venueName,
  venueGroupName,
  remove,
  clearAutocreate,
}: PhoneNumberCardProps) {
  const { formatMessage } = useLocales()
  const { data } = useGetAdminVenuePhoneNumbersDataQuery({ venueId })
  const [phoneNumberId, provider, autocreateRequested, autocreatedStatus, twilioPhoneType, isWhatsappRegistrationComplete] = useWatchMany(
    field,
    ['id', 'provider', 'twilioAutocreate', 'twilioAutocreatedStatus', 'twilioPhoneType', 'isWhatsappRegistrationComplete']
  )
  const { error: fieldError } = field.getFieldState()

  const isTwilio = provider === SMSProvider.TWILIO
  const isSMSGlobal = provider === SMSProvider.SMS_GLOBAL
  const isA2P = twilioPhoneType === TwilioPhoneType.A2P
  const isAutocreatePending = autocreatedStatus === TwilioAutocreateStatus.AUTOCREATE_PENDING
  const isAutocreated = isAutocreatePending || autocreatedStatus === TwilioAutocreateStatus.AUTOCREATE_COMPLETE
  const isNewPhoneNumber = !phoneNumberId
  const hasTollFreeErrors = fieldError && !!Object.keys(fieldError).filter(k => k === 'tollFreeVerification').length
  const statusInfo = useWatch(field.prop('statusInfo'))

  useEffect(() => {
    if (isNewPhoneNumber && !(isTwilio && isAutocreatePending && twilioPhoneType === TwilioPhoneType.TOLL_FREE)) {
      field.prop('tollFreeVerification').clear()
    }
  }, [isNewPhoneNumber, isTwilio, isAutocreatePending, twilioPhoneType, field])

  if (!data) {
    return null
  }

  const { purposes, smsProviders, twilioPhoneTypes, twilioAutocreatePhoneTypes, usCAOnlyPhoneTypes, numericLongcodePhoneTypes } = data

  return (
    <BaseSection
      title={
        isNewPhoneNumber
          ? formatMessage(adminVenuePhoneNumbersMessages.newPhoneNumberTitle)
          : formatMessage(adminVenuePhoneNumbersMessages.configuredPhoneNumberTitle)
      }
      description={
        <VStack spacing="xs" mt="xs" alignItems="flex-start">
          {!isNewPhoneNumber && isAutocreated && (
            <AutoreatedLabel text={formatMessage(adminVenuePhoneNumbersMessages.twilioAutocreatedText)} />
          )}
          {!isNewPhoneNumber && !isAutocreated && <ManuallyCreatedLabel text={formatMessage(adminVenuePhoneNumbersMessages.manualText)} />}
          {typeof isWhatsappRegistrationComplete === 'boolean' && (
            <HStack spacing="xs">
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
              }
              <Icon name="VMSWeb-whatsapp" size="sm" color={isWhatsappRegistrationComplete ? 'successText' : 'warning'} />
              <Text color={isWhatsappRegistrationComplete ? 'successText' : 'warning'}>
                {formatMessage(
                  isWhatsappRegistrationComplete
                    ? adminVenuePhoneNumbersMessages.whatsAppRegistrationComplete
                    : adminVenuePhoneNumbersMessages.whatsAppRegistrationPending
                )}
              </Text>
            </HStack>
          )}
        </VStack>
      }
      actions={
        <Button variant={isNewPhoneNumber ? 'tertiary-warning' : 'secondary-warning'} onClick={remove} data-test="phone-number-card-button">
          {isNewPhoneNumber ? formatMessage(commonMessages.cancel) : formatMessage(adminVenuePhoneNumbersMessages.deletePhoneNumber)}
        </Button>
      }
    >
      {isNewPhoneNumber && (
        <InputGroup>
          <FluidCheckbox
            field={field.prop('twilioAutocreate')}
            info={formatMessage(adminVenuePhoneNumbersMessages.twilioAutocreateInfo)}
            text={formatMessage(adminVenuePhoneNumbersMessages.twilioAutocreateInput)}
            disabled={!isTwilio}
          />
        </InputGroup>
      )}
      {isAutocreatePending && (
        <Box pl="lm" pr="lm" pt="m" pb="m">
          <Text>{formatMessage(adminVenuePhoneNumbersMessages.twilioAutocreatePending)}</Text>
        </Box>
      )}
      {!isAutocreatePending && (
        <>
          <InputGroup>
            <FluidSelect
              primary={formatMessage(adminVenuePhoneNumbersMessages.providerPrimary)}
              secondary={formatMessage(adminVenuePhoneNumbersMessages.providerSecondary)}
              options={smsProviders}
              field={field.prop('provider')}
              onChange={clearAutocreate}
              disabled={isAutocreated || autocreateRequested}
            />
          </InputGroup>
          {isTwilio && (
            <>
              <InputGroup>
                <FluidCheckbox
                  field={field.prop('allowForceSendAsMms')}
                  text={formatMessage(adminVenuePhoneNumbersMessages.allowForceSendAsMmsInput)}
                  info={formatMessage(adminVenuePhoneNumbersMessages.allowForceSendAsMmsInfo)}
                />
              </InputGroup>
              <InputGroup>
                <FluidSelectNullable
                  primary={formatMessage(adminVenuePhoneNumbersMessages.twilioTypePrimary)}
                  secondary={formatMessage(adminVenuePhoneNumbersMessages.twilioTypeSecondary)}
                  options={!phoneNumberId && autocreateRequested ? twilioAutocreatePhoneTypes : twilioPhoneTypes}
                  field={field.prop('twilioPhoneType')}
                  disabled={isAutocreated}
                />
                {isA2P && (
                  <FluidInput
                    primary={formatMessage(adminVenuePhoneNumbersMessages.campaignIdPrimary)}
                    secondary={formatMessage(adminVenuePhoneNumbersMessages.campaignIdSecondary)}
                    field={field.prop('twilioMessagingCampaignId')}
                  />
                )}
              </InputGroup>
            </>
          )}
          <InputGroup>
            {!autocreateRequested && (
              <FluidControl
                field={field.prop('smsPhoneNumber')}
                primary={formatMessage(adminVenuePhoneNumbersMessages.phoneNumberPrimary)}
                secondary={formatMessage(adminVenuePhoneNumbersMessages.phoneNumberSecondary)}
              >
                {phoneNumberId ||
                !twilioPhoneType ||
                provider !== SMSProvider.TWILIO ||
                !numericLongcodePhoneTypes.includes(twilioPhoneType) ? (
                  <FormInput field={field.prop('smsPhoneNumber')} disabled={isAutocreated} />
                ) : (
                  <FormPhoneNumberInput
                    field={field.prop('smsPhoneNumber')}
                    countryCodeWhiteList={usCAOnlyPhoneTypes.includes(twilioPhoneType) ? US_CA_COUNTRY_CODES : undefined}
                  />
                )}
              </FluidControl>
            )}
            <FluidSelect
              primary={formatMessage(adminVenuePhoneNumbersMessages.purposePrimary)}
              secondary={formatMessage(adminVenuePhoneNumbersMessages.purposeSecondary)}
              options={purposes}
              field={field.prop('purpose')}
            />
          </InputGroup>
          {isTwilio && !autocreateRequested && (
            <InputGroup>
              <FluidInput
                primary={formatMessage(adminVenuePhoneNumbersMessages.twilioAccountSidPrimary)}
                field={field.prop('twilioAccountSid')}
                disabled={isAutocreated}
              />
              <FluidInput
                primary={formatMessage(adminVenuePhoneNumbersMessages.twilioAuthTokenPrimary)}
                field={field.prop('twilioAuthToken')}
                placeholder={isAutocreated ? formatMessage(adminVenuePhoneNumbersMessages.twilioHiddenAuthToken) : undefined}
                disabled={isAutocreated}
              />
            </InputGroup>
          )}
          {isTwilio && (autocreateRequested || isAutocreatePending || isAutocreated) && twilioPhoneType === TwilioPhoneType.TOLL_FREE ? (
            <TollFreeVerificationCard
              disabled={!isNewPhoneNumber}
              field={field.prop('tollFreeVerification')}
              venueId={venueId}
              venueGroupId={venueGroupId}
              venueName={venueName}
              venueGroupName={venueGroupName}
              initiallyCollapsed={!isNewPhoneNumber}
              statusInfo={statusInfo}
            />
          ) : (
            hasTollFreeErrors && (
              <InputGroup display="flex">
                <Status kind="error">{formatMessage(adminVenuePhoneNumbersMessages.tfvTechnicalError)}</Status>
              </InputGroup>
            )
          )}
          {isSMSGlobal && (
            <InputGroup>
              <FluidInput
                primary={formatMessage(adminVenuePhoneNumbersMessages.smsGlobalUsernamePrimary)}
                field={field.prop('smsProviderUsername')}
              />
              <FluidInput
                primary={formatMessage(adminVenuePhoneNumbersMessages.smsGlobalPasswordPrimary)}
                field={field.prop('smsProviderPassword')}
              />
            </InputGroup>
          )}
        </>
      )}
    </BaseSection>
  )
}
